import { createContext, useReducer } from 'react';

/**
 * currentUser:
 * {
 *    email: "john.doe@example.com",
 *    accessLevel: {
 *      wh: true,
 *      dd: true,
 *      1824: true,
 *      hasMultiple: true,
 *      defaultBrand: wh|dd|1824,
 *      total: 3,
 *    }
 * }
 */

const initialState = {
  brand: 'aaco',
  currentUser: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_BRAND': {
      let brand = action.payload;
      // if user login
      if (!!state?.user) {
        // check user access level; before change the brand
        const { accessLevel } = state?.user;
        const canChangeBrand = accessLevel?.[action.payload];
        if (!canChangeBrand) {
          brand = sessionStorage.getItem('brand') || state.brand;
        }
      }
      return {
        ...state,
        brand,
      };
    }
    case 'SET_USER':
      let brand = 'aaco';
      const { accessLevel } = action.payload;

      if (accessLevel.total === 1) {
        brand = accessLevel.defaultBrand;
      } else {
        const savedBrand = sessionStorage.getItem('brand');
        if (savedBrand && accessLevel[savedBrand]) {
          brand = savedBrand;
        } else {
          brand = 'dd';
        }
      }

      return {
        ...state,
        user: action.payload,
        brand,
      };
  }
};

const StateContext = createContext(initialState);
const DispatchContext = createContext(() => {});

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export { StateContext, DispatchContext, ContextProvider };
