import { useEffect, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import { ParallaxProvider } from 'react-scroll-parallax';

import { ContextProvider, DispatchContext, StateContext } from '../context';

import { apiBaseUrl } from '@/utils/helper';

import { GTM_ID } from '@/utils/constants';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/globals.css';

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
      />
      <ContextProvider>
        <ParallaxProvider>
          <ThemeSelector>
            <AuthGuard>
              <Component {...pageProps} />
            </AuthGuard>
          </ThemeSelector>
        </ParallaxProvider>
      </ContextProvider>
    </>
  );
}

function ThemeSelector({ children }) {
  const { brand } = useContext(StateContext);

  useEffect(() => {
    const BRAND = {
      dd: 'dd-en',
      wh: 'wh',
      1824: 1824,
      default: 'aaco',
    };
    document.body.className = BRAND[brand] ?? BRAND.default;
  }, [brand]);

  return children;
}

function AuthGuard({ children }) {
  const [authInitialized, setAuthInitialized] = useState(false);
  const { user } = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const router = useRouter();
  const { pathname, push } = router;
  const LOGIN_PATH = '/';

  //check if token is exist in session storage, if exist, set auth
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      fetch(`${apiBaseUrl()}/api/me`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const { email, accessLevel } = data;
          if (email && accessLevel) {
            dispatch({
              type: 'SET_USER',
              payload: {
                email,
                accessLevel,
              },
            });
          } else {
            setAuthInitialized(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setAuthInitialized(true);
        });
    } else {
      setAuthInitialized(true);
    }
  }, [dispatch]);

  //if user context has been set, set setAuthInitialized to true
  useEffect(() => {
    if (user) {
      setAuthInitialized(true);
    }
  }, [user]);

  //redirect to login page is user has not loggedin yet
  useEffect(() => {
    if (authInitialized && pathname !== LOGIN_PATH && !user) {
      push(LOGIN_PATH);
    }
  }, [authInitialized, pathname, push, user]);

  if (!authInitialized || (pathname !== LOGIN_PATH && !user)) {
    return null;
  }

  return children;
}

export default MyApp;
