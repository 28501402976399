import { getOriginSiteUrl, is1824Brand } from './helper';

export const GTM_ID = 'GTM-TH2C3DG';

export const sectionHeaderPosition = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const sectionHeaderType = {
  PAGE: 'page',
  SECTION: 'section',
  LEVEL_2: 'level-2',
  LEVEL_3: 'level-3',
  PAGE_LARGE: 'page-large',
};

export const sectionHeaderSize = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
};

export const ctaStyle = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  // only on DD
  DD_PRIMARY_FILLED: 'DDPrimaryFilled',
  DD_PRIMARY_LINE: 'DDPrimaryLine',
  DD_SECONDARY: 'DDSecondary',
  DD_TERTIARY: 'DDTertiary',
  DD_DEFAULT: 'DDDefault',
};

export const resourceMapping = {
  BRAND: {
    folder: 'Brand Guidelines',
    label: 'Brand Guidelines',
    imgDD:
      'https://res.cloudinary.com/aaco/image/upload/v1689836848/Distributor%20Portal/Darling%20Downs/homepage-grid-brand-guidelines_cazui1.png',
    imgWH:
      'https://res.cloudinary.com/aaco/image/upload/v1712209965/Distributor%20Portal/Westholme/WH_Portal_Tile_ovbrqc.png',
    img1824:
      'https://res.cloudinary.com/aaco/image/upload/v1709181615/Distributor%20Portal/1824/homepage-grid-brand-guidelines_uxbxld.svg',
  },
  COLATERAL: {
    folder: 'Brand Collateral (Digital and Print Ready)',
    label: 'Brand Collateral (Digital & Print Ready)',
    imgDD:
      'https://res.cloudinary.com/aaco/image/upload/v1682560391/Distributor%20Portal/Darling%20Downs/homepage-grid-brand-collateral_usqzef.png',
    imgWH:
      'https://res.cloudinary.com/aaco/image/upload/v1710910437/Distributor%20Portal/Westholme/Westholme_Portal_Brand_Collateral_twdpjw.jpg',
    img1824:
      'https://res.cloudinary.com/aaco/image/upload/v1706497488/1824/1824%20Station%20Images%20Final%20Edited/1824_Station_Shoot_2023_Edited_19_d3bm5g.jpg',
  },
  MEDIA: {
    folder: 'Images and Videos',
    label: 'Images and Videos',
    imgDD:
      'https://res.cloudinary.com/aaco/image/upload/v1682560390/Distributor%20Portal/Darling%20Downs/homepage-grid-image-and-video_oelosk.jpg',
    imgWH:
      'https://res.cloudinary.com/aaco/image/upload/v1710739876/Distributor%20Portal/Westholme/zepqewfywzx9q1u2twfo.jpg',
    img1824:
      'https://res.cloudinary.com/aaco/image/upload/v1706497630/1824/1824%20Station%20Images%20Final%20Edited/1824_Station_Shoot_2023_Edited_65_e3ebs3.jpg',
  },
  PRODUCT: {
    folder: 'Product Details',
    label: 'Product Details',
    imgDD:
      'https://res.cloudinary.com/aaco/image/upload/v1682562371/Distributor%20Portal/Darling%20Downs/homepage-external-grid-products_rt6zpf.png',
    imgWH:
      'https://res.cloudinary.com/aaco/image/upload/v1710808296/Distributor%20Portal/Westholme/bipujsckjgnnijcebclx.jpg',
    img1824:
      'https://res.cloudinary.com/aaco/image/upload/v1707694349/1824/1824%20Product%20Images%20Final%20Edited/1824_Tomahawk_Raw_Styled_Edited_2_bfevh2.jpg',
  },
  TUTORIAL: {
    folder: 'Tutorials and Education',
    label: 'Tutorials & Education',
    imgDD:
      'https://res.cloudinary.com/aaco/image/upload/v1682560390/Distributor%20Portal/Darling%20Downs/homepage-grid-tutorials_oizyvb.png',
    imgWH:
      'https://res.cloudinary.com/aaco/image/upload/v1710740116/Distributor%20Portal/Westholme/xg7lvozkyvz6evnvkxlr.jpg',
    img1824:
      'https://res.cloudinary.com/aaco/image/upload/v1706497483/1824/1824%20Station%20Images%20Final%20Edited/1824_Station_Shoot_2023_Edited_22_pindla.jpg',
  },
};

export const resourceKeys = Object.keys(resourceMapping);

export const METAS = {
  wh: {
    shortcut: '/wh.favicon.ico?v=2',
    appleTouch: '/wh.apple-touch-icon.png?v=2',
    icon32: '/wh.favicon-32x32.png?v=2',
    icon16: '/wh.favicon-16x16.png?v=2',
    icon: '/wh.favicon.ico?v=2',
    manifest: '/wh.site.webmanifest',
  },
  dd: {
    shortcut: '/dd.favicon.ico',
    appleTouch: '/dd.apple-touch-icon.png',
    icon32: '/dd.favicon-32x32.png',
    icon16: '/dd.favicon-16x16.png',
    icon: '/dd.favicon.ico',
    manifest: '/dd.site.webmanifest',
  },
  1824: {
    shortcut: '/1824.favicon.ico',
    appleTouch: '/1824.apple-touch-icon.png',
    icon32: '/1824.favicon-32x32.png',
    icon16: '/1824.favicon-16x16.png',
    icon: '/1824.favicon.ico',
    manifest: '/1824.site.webmanifest',
  },
  aaco: {
    shortcut: '/aaco.favicon.ico',
    appleTouch: '/aaco.apple-touch-icon.png',
    icon32: '/aaco.favicon-32x32.png',
    icon16: '/aaco.favicon-16x16.png',
    icon: '/aaco.favicon.ico',
    manifest: '/aaco.site.webmanifest',
  },
};

export const CONTENT_WH = {
  title: 'Distributor Portal',
  subtitle:
    'Welcome to the Westholme distributor portal, designed to provide you easy access to our comprehensive library of branded material and essential information.',
  imageBanner:
    'https://res.cloudinary.com/aaco/image/upload/v1711579876/Distributor%20Portal/Westholme/AACo_LaBelle_Day_10_Drone_01063_rsngjg.jpg',
  bannerTitle: 'Westholme Has A New Look ',
  bannerTitleDescription:
    'We’re re-launching the Westholme brand to get us closer to our greatest asset, the land. Beginning in mid-April 2024 we will be introducing our new brand with a new website, new brand guidelines, and in May, a global brand campaign to raise awareness for what we think is the most versatile Wagyu in the world. Stay tuned for updates, explore the distributor portal for new brand materials, and visit our website for more information on all things Westholme.',
  bannerButtonText: 'Learn more',
  titleCard1: 'Explore delightful recipes',
  linkTextCard1: 'MASTERCLASS',
  linkCard1: 'masterclass',
  imageCard1:
    'https://res.cloudinary.com/aaco/image/upload/v1683806196/Distributor%20Portal/Westholme/homepage-site-recipe_pgmywl.png',
  imageCard1Mobile:
    'https://res.cloudinary.com/aaco/image/upload/v1683806195/Distributor%20Portal/Westholme/homepage-site-recipe-sm_brg39k.png',
  imageAltCard1: 'Go to masterclass',
  titleCard2: 'Hear from fellow chefs',
  linkTextCard2: 'MEET THE CHEFS',
  linkCard2: 'chefs',
  imageCard2:
    'https://res.cloudinary.com/aaco/image/upload/v1683806195/Distributor%20Portal/Westholme/homepage-site-chef_ssqvvy.png',
  imageCard2Mobile:
    'https://res.cloudinary.com/aaco/image/upload/v1683806196/Distributor%20Portal/Westholme/homepage-site-chef-sm_hckqoq.png',
  imageAltCard2: 'Go to meet the chefs',
  titleCard3: 'Learn more about Westholme',
  linkTextCard3: 'OUR STORY',
  linkCard3: 'our-story',
  imageCard3:
    'https://res.cloudinary.com/aaco/image/upload/v1683806196/Distributor%20Portal/Westholme/homepage-site-our-story_pmvesc.png',
  imageCard3Mobile:
    'https://res.cloudinary.com/aaco/image/upload/v1683806196/Distributor%20Portal/Westholme/homepage-site-our-story-sm_fidlul.png',
  imageAltCard3: 'Go to our story',
};

export const CONTENT_DD = {
  title: 'Distributor Portal',
  subtitle:
    'Welcome to the Darling Downs distributor portal, designed to provide you easy access to our comprehensive library of branded material and essential information.',
  imageBanner:
    'https://res.cloudinary.com/aaco/image/upload/w_1280,c_fill,q_auto,f_auto,g_auto/v1672991737/Darling%20Downs/Pages/Home/08.20_Darling_Downs_Korea_Raw_Oyster_Blade_HighRes_2_pga6ks.jpg',
  bannerTitle: 'Building Brands Together',
  bannerTitleDescription:
    'Check in for regular updates or please feel free to contact us anytime. ',
  bannerButtonText: 'Start 5 mins survey',
  titleCard1: 'Recipes',
  subtitleCard1: 'Discover new and delicious culinary creations',
  linkCard1: 'our-cuts',
  imageCard1:
    'https://res.cloudinary.com/aaco/image/upload/v1682560390/Distributor%20Portal/Darling%20Downs/homepage-external-grid-recipes_dr8hph.jpg',
  imageCard1Mobile:
    'https://res.cloudinary.com/aaco/image/upload/v1682560390/Distributor%20Portal/Darling%20Downs/homepage-external-grid-recipes_dr8hph.jpg',
  imageAltCard1: 'Go to our recipes',
  titleCard2: 'Our products',
  subtitleCard2: 'Find the perfect product for your needs',
  linkCard2: 'our-cuts',
  imageCard2:
    'https://res.cloudinary.com/aaco/image/upload/v1682562319/Distributor%20Portal/Darling%20Downs/homepage-grid-products_itgwp2.png',
  imageCard2Mobile:
    'https://res.cloudinary.com/aaco/image/upload/v1682562319/Distributor%20Portal/Darling%20Downs/homepage-grid-products_itgwp2.png',
  imageAltCard2: 'Go to our products',
  titleCard3: 'Our story',
  subtitleCard3: 'Learn about the passion behind our products',
  linkCard3: 'our-story',
  imageCard3:
    'https://res.cloudinary.com/aaco/image/upload/v1682560391/Distributor%20Portal/Darling%20Downs/homepage-external-grid-chefs_iksm0v.png',
  imageAltCard3: 'Go to our products',
  imageCard3Mobile:
    'https://res.cloudinary.com/aaco/image/upload/v1682560391/Distributor%20Portal/Darling%20Downs/homepage-external-grid-chefs_iksm0v.png',
};

export const CONTENT_1824 = {
  title: 'Distributor Portal',
  subtitle:
    'Welcome to the 1824 distributor portal, designed to provide you easy access to our comprehensive library of branded material and essential information',
  imageBanner:
    'https://res.cloudinary.com/aaco/image/upload/v1706497466/1824/1824%20Station%20Images%20Final%20Edited/1824_Station_Shoot_2023_Edited_8_j9oevu.jpg',
  bannerTitle: 'Building Brands Together',
  bannerTitleDescription:
    'Check in for regular updates or please feel free to contact us anytime. ',
  bannerButtonText: 'Start 5 mins survey',
  titleCard1: 'Learn more about 1824',
  linkTextCard1: 'OUR STORY',
  linkCard1: '',
  imageCard1:
    'https://res.cloudinary.com/aaco/image/upload/q_auto/v1712810261/Distributor%20Portal/1824/1824_Fire_Grill_Shots_Edited_5__ihf9wb.jpg',
  imageCard1Mobile:
    'https://res.cloudinary.com/aaco/image/upload/q_auto/v1712810261/Distributor%20Portal/1824/1824_Fire_Grill_Shots_Edited_5__ihf9wb.jpg',
  imageAltCard1: 'Go to 1824',
  titleCard2: 'Contact us',
  imageCard2:
    'https://res.cloudinary.com/aaco/image/upload/v1706497539/1824/1824%20Station%20Images%20Final%20Edited/1824_Station_Shoot_2023_Edited_12_bw9ydn.jpg',
  imageCard2Mobile:
    'https://res.cloudinary.com/aaco/image/upload/v1706497539/1824/1824%20Station%20Images%20Final%20Edited/1824_Station_Shoot_2023_Edited_12_bw9ydn.jpg',
  imageAltCard2: 'Contact us',
  linkTextCard2: 'CONTACT US',
  linkCard2: 'contact-us',
  // linkTextCard2: 'OUR CUTS',
  // linkCard2: 'our-cuts',
};

export const LOGIN_BANNER = {
  wh: 'https://res.cloudinary.com/aaco/image/upload/v1710738793/Distributor%20Portal/Westholme/qlvhrel5zsriendvd0uq.jpg',
  dd: 'https://res.cloudinary.com/aaco/image/upload/v1682499628/Distributor%20Portal/Darling%20Downs/login-banner_mumhxo.jpg',
  1824: 'https://res.cloudinary.com/aaco/image/upload/v1706497447/1824/1824%20Station%20Images%20Final%20Edited/1824_Station_Shoot_2023_Edited_25_rhdkrc.jpg',
  default:
    'https://res.cloudinary.com/aaco/image/upload/v1682499628/Distributor%20Portal/Westholme/login-banner_fvcxcz.jpg',
};

export const getSubnav = (brand) => [
  {
    text: 'Brand Guidelines',
    link: `/portal/${brand}/result?category=brand`,
  },
  {
    text: 'Brand Collateral',
    link: `/portal/${brand}/result?category=colateral`,
  },
  {
    text: 'Images & Videos',
    link: `/portal/${brand}/result?category=media`,
  },
  {
    text: '',
  },
  {
    text: 'Product Details',
    link: `/portal/${brand}/result?category=product`,
  },
  {
    text: 'Tutorials & Education',
    link: `/portal/${brand}/result?category=tutorial`,
  },
  {
    text: 'Contact us',
    link: `${getOriginSiteUrl(brand)}${
      is1824Brand(brand) ? 'contact-us' : 'contact'
    }`,
    target: '_blank',
  },
];
